import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "@ui/button";
import Text from "@ui/text";
import PropTypes from "prop-types";
import SectionTitle from "@ui/section-title";
import Heading from "@ui/heading";
import Image from "@ui/image";

import {
  KeybenifitsWrapper,
  KeybenifitsInner,
  KeybenifitsList,
  KeybenifitsItem,
  KeybenifitsItemFront,
  KeybenifitsItemBack,
  KeybenifitsItemIconwrap,
  KeybenifitsItemTextwrap,
} from "./style";

const KeyBenifits = React.memo(({ HeadingStyle, data, layout, ...props}) =>{
  return (
    <KeybenifitsWrapper layout={layout} {...props} id={data.section}>
      <KeybenifitsInner>
        <Container>
          <Row>
            <Col>
              <SectionTitle
                title={data.section_title?.title}
                subtitle={data.section_title?.subtitle}
                description={data.section_title?.description}
              />
            </Col>
          </Row>

          <Row >
            <Col>
              <KeybenifitsList>
                {data &&
                  data.items.map((el, index) => (
                    <KeybenifitsItem key={index}>
                      <KeybenifitsItemFront className="flipFront">
                        <KeybenifitsItemIconwrap>
                          <Image
                            src={el.images[0].src}
                            alt={el.images[0]?.alt || "Icon"}
                          />
                        </KeybenifitsItemIconwrap>
                        <KeybenifitsItemTextwrap>
                          <Heading {...HeadingStyle}>{el.title}</Heading>
                        </KeybenifitsItemTextwrap>
                      </KeybenifitsItemFront>
                      <KeybenifitsItemBack className="showBack">
                        <Text>{el.description}</Text>
                        {/* <Anchor path={el.path} key={index}>LEARN MORE</Anchor> */}
                      </KeybenifitsItemBack>
                    </KeybenifitsItem>
                  ))}
              </KeybenifitsList>
            </Col>
          </Row>
          {data?.buttons?.map(({ id, content, ...rest }) => (
            <Button key={id} {...rest} target="_blank" className="btn-learn-more">
              {content}
            </Button>
          ))}
        </Container>
      </KeybenifitsInner>
    </KeybenifitsWrapper>
  );
})

KeyBenifits.propTypes = {
  SectionTitleStyle: PropTypes.object,
  HeadingStyle: PropTypes.object,
};
KeyBenifits.defaultProps = {
  SectionTitleStyle: {
    border: {
      left: {
        color: "#fff",
      },
    },
    HeadingStyle: {
      as: "h6",
      color: "#fff",
      fontweight: "bold",
      fontSize: "22px",
    },
  },
};
export default KeyBenifits;
