import styled, {css, themeGet, device } from "@styled";
import benefitsBg from "@data/images/bg/benifitBg.png";
import benefitLayoutOneBg from "@data/images/bg/benefitLayoutOneBg.jpg"
export const KeybenifitsWrapper = styled.section`
  padding: 90px 0;
  background: linear-gradient(
    320deg,
    ${themeGet("colors.primaryDark")},
    ${themeGet("colors.primary")}
  );
  background: url(${benefitsBg});
  background-size:cover;
  background-position:right bottom;
  background-repeat:no-repeat;
  .section-title {
    h2 {
      border-color: #fff;
    }
    p{
      color:#fff;
    }
  }
  ${(props) =>
    props.layout === 1 &&
    css`
      background:url(${benefitLayoutOneBg});
      background-size:cover;
      background-position:right center;
      background-repeat:no-repeat;
      .btn-learn-more{
        margin: 70px auto 0px;
        display: flex;
        width: fit-content;;
      }
  `}
`;
export const KeybenifitsInner = styled.div``;
export const KeybenifitsList = styled.div`
  display: flex;
  flex-wrap:wrap;
  justify-content:center;
  grid-gap: 10px;
`;
export const KeybenifitsItem = styled.div`
  min-height: 150px;
  position: relative;
  border-radius: 4px;
  flex: 0 1 100%;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid rgb(255 255 255 / 55%);
  perspective: 1000px;
  transition: ${(props) => props.theme.transition};
  ${device.medium} {
    flex: 0 1 calc(50% - 10px);
  }
  &:hover {
    transform: rotateY(180deg);
    .flipFront {
      opacity: 0;
      visibility: hidden;
    }
    .showBack{
      opacity:1;
    }
  }
`;
export const KeybenifitsItemFront = styled.div`
  min-height: 150px;
  background-color: rgba(255,138,101,0.5019607843137255);
  padding: 30px 25px;
  display: flex;
  align-items: center;
  transition: ${(props) => props.theme.transition};
  backface-visibility: hidden;
  position: relative;
  height: 100%;
  z-index: 2;
`;
export const KeybenifitsItemBack = styled.div`
  transition: ${(props) => props.theme.transition};
  transform: rotateY(180deg);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 30px 25px;
  display:flex;
  align-items:center;
  height: 100%;
  transform: rotateY(180deg);
  transition: ${(props) => props.theme.transition};
  p {
    font-size: 15px;
    @media ${device.large} {
      font-size: 14px;
    }
  }
  background-color: #ffc1ac;
  opacity:0;
  a {
    color: #fa4f19;
    font-size: 16px;
    font-weight: bold;
    @media ${device.large} {
      font-size: 14px;
    }
  }
`;
export const KeybenifitsItemIconwrap = styled.div`
  min-width: 88px;
  width: 88px;
  height: 88px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  margin-right: 20px;
`;
export const KeybenifitsItemTextwrap = styled.div`
  h6,
  h2 {
    font-size: 22px;
    font-weight: bold;
    color: #fff;
    margin: 0;
    @media ${device.large} {
      font-size: 18px;
    }
  }
`;
