import styled, { css, device, themeGet } from "@styled";
import blueWaveBg from "@data/images/bg/blue-wave-bg.webp";
import blueWaveGradientBg from "@data/images/bg/blueWaveGradientBg.png";
import orangewaveBg from "@data/images/bg/orangewaveBg.webp";

export const BoxSectionWrapper = styled.section`
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 100px 0;
  background: linear-gradient(
    320deg,
    ${themeGet("colors.secondaryDark")},
    ${themeGet("colors.secondary")}
  );
  ${device.medium} {
    padding: 166px 0;
  }
  .section-title {
    p {
      color: #fff;
    }
  }
  ${(props) =>
    props.layout === 1 &&
    css`
      background: linear-gradient(
        320deg,
        ${themeGet("colors.secondaryDark")},
        ${themeGet("colors.secondary")}
      );
    `}
  ${(props) =>
    props.layout === 2 &&
    css`
      padding-top: 170px;
      background: url(${blueWaveBg});
      background-position: center top;
      background-size: cover;
      background-repeat: no-repeat;
    `}

${(props) =>
    props.layout === 3 &&
    css`
      background: linear-gradient(
        320deg,
        ${themeGet("colors.primaryDark")},
        ${themeGet("colors.primary")}
      );
      .section-title {
        h6 {
          color: #fff;
        }
        h2 {
          border-color: #fff;
        }
        p {
          color: #fff;
        }
      }
    `}
${(props) =>
    props.layout === 4 &&
    css`
      padding-top: 170px;
      background: url(${orangewaveBg});
      background-position: center top;
      background-size: cover;
      background-repeat: no-repeat;
      .section-title {
        h6 {
          color: #fff;
        }
        h2 {
          border-color: #fff;
        }
        p {
          color: #fff;
        }
      }
    `}
    ${(props) =>
      props.layout === 5 &&
      css`
        padding-top: 100px;
        padding-bottom:60px;
        background-color:rgb(248, 248, 248) !important;
        background: url(${blueWaveGradientBg});
        background-position: left top;
        background-size: cover;
        background-repeat: no-repeat;
        ${device.xlarge} {
         padding-bottom:100px;
        }
        .key-benefits-box{
            gap: 20px;
          & > div{
            padding-right:2px;
            border-color:rgb(219, 181, 246);
            & > div + div{
              border-color:rgb(219, 181, 246);
            }
            h6{
              margin:0 0  15px;
            }
            p{
              font-size:14px;
              color:rgb(219, 181, 246);
              margin:0;
            }
          }
        }
        .btn-learn-more{
          margin: 70px auto 0px;
          display: flex;
          width: fit-content;
        }
      `}
      ${(props) =>
        props.layout === 6 &&
        css`
          padding-top: 100px;
          padding-bottom:60px;
          background: url(${blueWaveGradientBg});
          background-position: left top;
          background-size: cover;
          background-repeat: no-repeat;
          ${device.xlarge} {
           padding-bottom:100px;
          }
          .key-benefits-box{
              gap: 20px;
            & > div{
              padding-right:2px;
              border-color:rgb(219, 181, 246);
              & > div + div{
                border-color:rgb(219, 181, 246);
              }
              h6{
                margin:0 0  15px;
              }
              p{
                font-size:14px;
                color:rgb(219, 181, 246);
                margin:0;
              }
            }
          }
          .btn-learn-more{
            margin: 70px auto 0px;
            display: flex;
            width: fit-content;
          }
      `}
`;

export const BoxSectionInner = styled.div``;

export const BoxSectionWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 10px;
  ${device.medium} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${device.xlarge} {
    grid-template-columns: repeat(3, 1fr);
  }
`;
export const BoxSectionWrapInner = styled.div`
  padding: 15px 0;
  border: 1px solid rgb(255 255 255 / 55%);
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  background: rgb(255 255 255 / 11%);
  transition: ${themeGet("transition")};
  &:hover {
    box-shadow: 0 0 40px -16px rgb(255 255 255);
    transform: translateY(-3px);
  }
`;
export const BoxSectionIconwrap = styled.div`
  min-width: 125px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const BoxSectionTextWrap = styled.div`
  border-left: 1px solid rgb(255 255 255 / 55%);
  padding-left: 20px;
  h6 {
    margin: 20px 0;
    font-size: 22px;
    font-weight: bold;
  }
  h4 {
    margin: 20px 0;
    font-size: 20px;
    font-weight: bold;
    ${device.xxlarge} {
      font-size: 22px;
    }
  }
  p {
    color: rgb(255 255 255 / 70%);
    font-size: 14px;
    ${device.xlarge} {
      font-size: 15px;
    }
    ${device.xxlarge} {
      font-size: 17px;
    }
  }
  ul {
    li {
      color: #ccb8fe;
      font-size: 15px;
      margin-bottom: 12px;
      line-height: 1;
      padding-left: 15px;
      position: relative;
      &::before {
        background: #ccb8fe;
      }
    }
  }
`;
export const BoxBottomImgWrap = styled.div`
  margin-bottom: -100px;
  text-align: center;
  padding-top: 70px;
  ${device.medium} {
    margin-bottom: -166px;
  }
`;
