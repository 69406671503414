import styled, { css, themeGet, device } from "@styled";

export const IntroWrapper = styled.section`
  ${(props) =>
    props.layout === 1 &&
    css`
      padding: 60px 0 100px;
      ${device.medium} {
        padding: 100px 0 100px;
      }
      background: #fff;
    `}
  ${(props) =>
    props.layout === 2 &&
    css`
      padding: 60px 0 100px;
      ${device.medium} {
        padding: 100px 0 100px;
      }
      background: #f8f8f8;
    `}

    ${(props) =>
      props.layout === 3 &&
      css`
        padding: 60px 0 30px;
        ${device.medium} {
          padding: 100px 0 100px;
        }
    `}
    ${(props) =>
      props.layout === 4 &&
      css`
        padding-top:0;
        padding-bottom:60px;
        ${device.large} {
          padding-bottom:100px;
        }
    `}
    ${(props) =>
      props.layout === 5 &&
      css`
        padding: 10px 0 40px 0;
        ${device.small} {
          padding: 30px 0 60px 0;
        }
        ${device.medium} {
          padding: 60px 0 100px 0;
        }
    `}
    ${(props) =>
      props.layout === 6 &&
      css`
        padding: 40px 0 0 0;
        ${device.small} {
          padding: 60px 0 0 0;
        }
        ${device.medium} {
          padding: 100px 0 0 0;
        }
    `}
`;

export const LeftBox = styled.div`
  @media ${device.medium} {
    margin-bottom: 20px;
  }
`;
export const Styledtitle = styled.h3`
  font-size: 20px;
  font-weight: 700;
  color: ${themeGet("colors.primary")};
`;
export const RightBox = styled.div`
  margin: 15px 0;
  ${device.large} {
    margin: 0;
    display: flex;
    justify-content: end;
  }
`;
