import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "@ui/wrapper";
import SectionTitle from "@ui/section-title";
import Image from "@ui/image";
import Button from "@ui/button";
import Accordion from "@ui/accordion";
import { IntroWrapper, LeftBox, Styledtitle, RightBox } from "./stye";
import Anchor from "@ui/anchor";
const IntroAreaReverse = ({ data, index, layout, ...props }) => {
  return (
    <IntroWrapper layout={layout} {...props} id={data.section}>
      <Container>
        <Row>
          <Col lg={12}>
            <SectionTitle
              subtitle={data.section_title?.subtitle}
              title={data.section_title?.title}
              description={data.section_title?.description}
            />
            {!!data.path && <Anchor key={index} path={data.path}>{data.name}</Anchor>}
          </Col>
        </Row>
        <Row>
          <Col lg={5}>
            <LeftBox>
              <Styledtitle dangerouslySetInnerHTML={{__html: data.texts?.[0].content}}></Styledtitle>
              {data?.items && <Accordion data={data.items} layout={2} />}
            </LeftBox>
            {data?.buttons?.map(({ id, content, ...rest }) => (
              <div key={id}>
              <Button m="7px" className="mt-lg-5 mt-4" {...rest}>
                {content}
              </Button>
              </div>
            ))}
          </Col>
          <Col lg={7}>
            <RightBox>
              {!!data.images &&
                <Image
                  src={data.images[0]?.src}
                  alt={data.images[0]?.alt || "Info"}
                  title={data.images[0]?.alt || "Info"}
                />
              }
            </RightBox>
          </Col>
        </Row>
      </Container>
    </IntroWrapper>
  );
};

IntroAreaReverse.propTypes = {
  layout: PropTypes.oneOf([1, 2, 3]),
};
IntroAreaReverse.defaultProps = {
  layout: 1
};
//};
export default IntroAreaReverse;
