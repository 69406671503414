import { useEffect } from "react";

const UseScroll = (callback, setIsMobile) => {
  useEffect(() => {
    const handleScroll = () => {
      callback(window.scrollY);
    };

    const handleResize = () => {
      if (window.innerWidth <= 412) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [callback, setIsMobile]);
};

export default UseScroll;
